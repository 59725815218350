<template>
  <div>
    <!-- <div>
      <p>{{ this.$route.params.id }}</p>
    </div> -->
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <b-button variant="gradient-secondary" class="mr-1" @click="$router.push({ name: 'annualcheck' })">กลับ</b-button>
          <b-button class="ml-1" v-b-modal.modal-1 variant="outline-primary"> ลงชื่อกรรมกาตรวจนับ </b-button>
          <!-- <b-button class="ml-1" v-b-modal.modal-2 variant="outline-primary">
            ผู้ให้ถ้อยคำสอบข้อเท็จจริง
          </b-button> -->

          <!-- basic modal -->
          <b-modal
            id="modal-1"
            title="ลงชื่อรายงานผลการตรวจสอบทรัพย์สิน"
            ok-only
            ok-title="ตกลง"
            size="md"
            @ok="inspectorSignName"
            no-close-on-backdrop
          >
            <b-card-text>
              <b-form-group label-cols="3" label-cols-lg="2" label="ประธานกรรมการ:">
                <v-select :options="alluser" label="firstname" v-model="inspector1" placeholder="ชื่อ-สกุล" @input="a_position(inspector1)" />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                <v-select :options="department_Lists" label="dep_id" placeholder="ตำแหน่ง" v-model="inspector_pos1" disabled />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="กรรมการ:">
                <v-select :options="alluser" label="firstname" v-model="inspector2" placeholder="ชื่อ-สกุล" @input="b_position(inspector2)" />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                <v-select :options="department_Lists" label="dep_id" placeholder="ตำแหน่ง" v-model="inspector_pos2" disabled />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="กรรมการเเละเลขานุการ:">
                <v-select :options="alluser" label="firstname" v-model="inspector3" placeholder="ชื่อ-สกุล" @input="c_position(inspector3)" />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                <v-select :options="department_Lists" label="dep_id" placeholder="ตำแหน่ง" v-model="inspector_pos3" disabled />
              </b-form-group>
            </b-card-text>
          </b-modal>

          <!-- <b-modal
            id="modal-2"
            title="ผู้ให้ถ้อยคำสอบข้อเท็จจริง"
            ok-only
            ok-title="ตกลง"
          >
            <b-card-text>
              <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อ:">
                <v-select
                  :options="alluser"
                  label="firstname"
                  v-model="middle2"
                  placeholder="ชื่อ-สกุล"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                <v-select
                  :options="department_Lists"
                  label="dep_id"
                  placeholder="ตำแหน่ง"
                  v-model="department2"
                />
              </b-form-group>
            </b-card-text>
          </b-modal> -->

          <hr />
          <div class="title head">
            <p class="h2" style="color: #558cef">แบบสำรวจทรัพย์สินประจำปี</p>
          </div>
          <!-- search input -->
          <div class="row justify-content-start">
            <div class="h4 ml-1" style="color: #558cef">รหัส :</div>
            <div class="h4">{{ this.$route.params.id.check_code }}</div>
            <div class="ml-1 h4 text-primary" text="primary">ปีงบประมาณ :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.budget_year }}</div>
            <div class="ml-1 h4 text-primary" text="primary">วันที่ :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.start_date }}</div>
            <div class="ml-1 h4 text-primary" text="primary">ถึงวันที่ :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.stop_date }}</div>
          </div>

          <hr />
          <div class="row justify-content-end">
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Excel
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="handlePagechange($event)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue';
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { BButton } from 'bootstrap-vue';
import { BFormFile } from 'bootstrap-vue';
import { BFormSelect } from 'bootstrap-vue';
import { BFormDatepicker } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import _ from 'lodash';
export default {
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  },
  data() {
    return {
      pageLength: 10,
      currentPage: 1,
      total: '',
      show: false,
      selected: null,
      options: [
        { value: null, text: 'เลือกประเภทหลักฐาน' },
        { value: 'a', text: 'ใบส่งของ/ใบกำกับาษี' },

        // { value: { C: '3PO' }, text: 'This is an option with object value' },
        // { value: 'd', text: 'This one is disabled', disabled: true },
      ],
      columns: [
        {
          label: 'ชื่อทรัพย์สิน',
          field: 'information',
        },
        {
          label: 'เลขทะเบียนทรัพย์สิน',
          field: 'e_number',
        },
        {
          label: 'ราคา',
          field: 'unitprice',
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: 'สถานภาพ',
          field: 'status',
        },
        {
          label: 'ประสิทธิภาพ',
          field: 'performance',
        },
        {
          label: 'สถานที่เก็บ',
          field: 'place',
        },
        {
          label: 'หมายเหตุ',
          field: 'remark',
        },
      ],
      rows: [],
      searchTerm: '',
      alluser: [],

      inspector1: '',
      inspector_pos1: '',
      inspector2: '',
      inspector_pos2: '',
      inspector3: '',
      inspector_pos3: '',

      department_Lists: [],
      department1: '',
      department2: '',
    };
  },
  mounted() {
    this.get_data_table();
    this.get_data_table_user();
    this.departmentLists();
  },
  created() {
    console.log(this.$route.params.id);
    _.isEmpty(this.$route.params.id.check_code) ? this.$router.push({ path: '/annual-check' }) : true;
  },
  methods: {
    async a_position(value) {
      // console.log(value);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.inspector_pos1 = res.data.message.data[0].dep_id;
    },
    async b_position(value) {
      // console.log(value);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.inspector_pos2 = res.data.message.data[0].dep_id;
    },
    async c_position(value) {
      // console.log(value);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.inspector_pos3 = res.data.message.data[0].dep_id;
    },
    async inspectorSignName() {
      const { access_token } = await this.access_token();
      const url = `${API}inspectorSignName/${this.$route.params.id.check_code}`;
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const data = {
        inspector1: this.inspector1.firstname,
        inspector_pos1: this.inspector_pos1.dep_id || this.inspector_pos1,
        inspector2: this.inspector2.firstname,
        inspector_pos2: this.inspector_pos2.dep_id || this.inspector_pos2,
        inspector3: this.inspector3.firstname,
        inspector_pos3: this.inspector_pos3.dep_id || this.inspector_pos3,
      };
      const res = await axios.put(url, data, head);
      // console.log(res.data.message);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'บันทึก',
        showConfirmButton: false,
        timer: 1500,
      });
    },
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    async departmentLists() {
      const { access_token } = await this.access_token();
      const url = `${API}departmentLists`;
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.department_Lists = res.data.message.data;
      // console.log(res.data.message.data);
    },
    async get_data_table_user() {
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      const res_user = res.data.message.data.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: 'แบบสำรวจทรัพย์สินประจำปี',
        columns: [
          {
            title: 'แบบสำรวจทรัพย์สินประจำปี',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'รายการ',
                field: 'information',
              },
              {
                title: 'เลขทะเบียนทรัพย์สิน',
                field: 'e_number',
              },
              {
                title: 'ราคา',
                field: 'unitprice_ex',
              },
              {
                title: 'สถานภาพ',
                field: 'status',
              },
              {
                title: 'ประสิทธิภาพ',
                field: 'performance',
              },
              {
                title: 'สถานที่เก็บ',
                field: 'place',
              },
              {
                title: 'หมายเหตุ',
                field: 'remark',
              },
            ],
          },
        ],
      });
    },
    handlePagechange(event) {
      this.currentPage = event;
      this.get_data_table();
    },
    handleChangePage(event) {
      this.pageLength = event;
      this.get_data_table();
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}readEquipmentCheckList?check_code=${this.$route.params.id.check_code}&_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;

      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res);
      this.rows = res.data.message.data.map((el) => {
        return {
          ...el,
          unitprice_ex: Number(el.unitprice),
        };
      });
      this.total = res.data.message.total;
      this.show = false;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },

    getfrom() {
      // console.log(id);
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
